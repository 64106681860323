@import '../../sass/main.scss';
@import '../../sass/utils/placeholders';

/* Introducing magic formula
Goodbye '@media screen' breakpoints

		calc(--px + -vw)

From smallest screen size to max screen view
First value is the starting point
Second value is the screen view addition

		works with all rules
		font-sizes / paddings / margins / widths x heights / ...

Resulting to --> 95% less breakpoints */

.header {
	width: 100%;
	height: auto;
	@extend %flex-between;
}
