@import '../utils/variables';
@import '../utils/mixins';
@import '../utils/placeholders';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');

* {
	padding-inline-start: unset;
	text-decoration: none;
	list-style: none;
	font-family: 'Montserrat', sans-serif;
	margin: unset;
	font-size: 24px;
	font-weight: 500;
}

button {
	border-width: unset;
	border-style: unset;
	padding: unset;
}

a {
	cursor: pointer;
	z-index: 100; // necessary for card links for example
	color: $main-color;
	text-decoration: unset;
}

i {
	font-style: unset;
}

footer {
	margin-top: auto;
}
