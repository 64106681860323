.redirecting {
	font-size: 20px;

	span {
		animation-name: dot;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;

		&:nth-child(2) {
			animation-delay: 0.2s;
		}

		&:nth-child(3) {
			animation-delay: 0.4s;
		}
	}
}

@keyframes dot {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
