%flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

%flex-around {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

%flex-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
