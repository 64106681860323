@import '../../sass/main.scss';

/* Introducing magic formula
Goodbye '@media screen' breakpoints

		calc(--px + -vw)

From smallest screen size to max screen view
First value is the starting point
Second value is the screen view addition

		works with all rules
		font-sizes / paddings / margins / widths x heights / ...

Resulting to --> 95% less breakpoints */

.card {
	position: relative;
	display: flex;
	justify-content: center;
	border-radius: 15px;
	aspect-ratio: 1/1;
	overflow: hidden;
	transition: ease-out 0.3s;

	@media screen and (max-width: 900px) {
		aspect-ratio: 4/3;
	}

	&__img {
		border-radius: 15px;
		transition: ease-out 0.3s;
	}

	&__title {
		z-index: 11;
		position: absolute;
		font-weight: 500;
		bottom: 20px;
		left: 20px;
		right: 20px;
		color: #fff;
		font-size: 18px;
	}

	&::after {
		content: '';
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
	}

	&:hover {
		box-shadow: 0 2px 8px rgb(0, 0, 0);
	}

	&:hover &__img {
		transform: scale(1.1);
	}
}
