@import '../../sass/main.scss';

/* Introducing magic formula
Goodbye '@media screen' breakpoints

		calc(--px + -vw)

From smallest screen size to max screen view
First value is the starting point
Second value is the screen view addition

		works with all rules
		font-sizes / paddings / margins / widths x heights / ...

Resulting to --> 95% less breakpoints */

.banner--main {
	user-select: none;
	position: relative;
	overflow: hidden;
	border-radius: 15px;
	@include flex();
	aspect-ratio: 3/1;
	transition: 0.2s;

	@media screen and (min-width: 600px) {
		aspect-ratio: 4/1;
	}

	@media screen and (min-width: 1100px) {
		aspect-ratio: 6/1;
	}

	&__img {
		border-radius: 15px;
		object-fit: cover;
		filter: brightness(0.7);
		width: 100%;
		height: auto;
	}

	&__title {
		position: absolute;
		padding-inline: 5%;
		color: white;
		font-size: calc(15px + 2.5vw);
	}
}

.banner--about {
	@extend .banner--main;
	width: 100%;
	aspect-ratio: 4/3;

	&__img {
		border-radius: 15px;
		object-fit: cover;
		filter: brightness(0.7);
		width: 100%;
		height: auto;
	}

	@media screen and (min-width: 600px) {
		aspect-ratio: 8/2;
	}

	@media screen and (min-width: 900px) {
		aspect-ratio: 5/1;
	}

	@media screen and (min-width: 1100px) {
		aspect-ratio: 7/1;
	}
}
