@import '../../sass/main.scss';

/* Introducing magic formula
Goodbye '@media screen' breakpoints

		calc(--px + -vw)

From smallest screen size to max screen view
First value is the starting point
Second value is the screen view addition

		works with all rules
		font-sizes / paddings / margins / widths x heights / ...

Resulting to --> 95% less breakpoints */

.slider {
	user-select: none;
	position: relative;
	@include flex();
	aspect-ratio: 4/3;
	border-radius: 25px;
	transition: 0.2s;

	@media screen and (min-width: 600px) {
		aspect-ratio: 6/3;
	}

	@media screen and (min-width: 900px) {
		aspect-ratio: 3/1;
	}

	&__image {
		position: absolute;
		object-fit: cover;
		width: 100%;
		height: 100%;
		border-radius: 25px;
	}

	&__arrow {
		position: absolute;
		height: calc(20px + 3vw);
		cursor: pointer;
		transition: 0.1s;

		&:hover {
			filter: drop-shadow(0 4px 1px $main-color);
		}

		&:active {
			transition: cubic-bezier(0.38, 0.62, 0.72, 0.99) forwards;
		}

		&--backward {
			left: 10px;
			transition-duration: 0.4s;

			&:active {
				left: 5px;
			}
		}

		&--forward {
			right: 10px;
			transition-duration: 0.4s;

			&:active {
				right: 5px;
			}
		}
	}

	.slideCount {
		position: absolute;
		left: 50%;
		bottom: calc(10px + 1vw);
		color: white;
		font-size: calc(12px + 0.6vw);
		letter-spacing: -1px;

		@media screen and (max-width: 600px) {
			display: none;
		}
	}
}
