@import '../../sass/main.scss';

/* Introducing magic formula
Goodbye '@media screen' breakpoints

		calc(--px + -vw)

From smallest screen size to max screen view
First value is the starting point
Second value is the screen view addition

		works with all rules
		font-sizes / paddings / margins / widths x heights / ...

Resulting to --> 95% less breakpoints */

@keyframes doorOpening--medium {
	0% {
		transform: rotateY(0deg);
	}

	50% {
		transform: rotateY(-180deg);
		left: 50%;
	}

	100% {
		transform: rotateY(0deg);
	}
}

.logo {
	position: relative;
	max-width: 40%;
	@include flex(row, space-between, flex-end);

	&__item {
		position: relative;
		@include flex;
		width: calc(15px + 1.5625vw);
		height: calc(15px + 1.5625vw);

		&--home {
			height: inherit;
			transform: translateY(calc(6px + 0.390625vw));
		}

		&--door {
			position: absolute;
			width: calc(5px + 0.7vw);
			height: calc(5px + 0.7vw);
			bottom: -15%;
			left: 38%;
			animation: doorOpening--medium 0.8s ease-in;
		}

		&--small {
			height: calc(10px + 1.5625vw);
		}
	}

	&:active &__item--door {
		animation-name: doorOpening--Medium; // doesn't work with small 'M'edium caps
	}
}
