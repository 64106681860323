@import '../../sass/main.scss';

/* Introducing magic formula
Goodbye '@media screen' breakpoints

		calc(--px + -vw)

From smallest screen size to max screen view
First value is the starting point
Second value is the screen view addition

		works with all rules
		font-sizes / paddings / margins / widths x heights / ...

Resulting to --> 95% less breakpoints */

.accommodation-page {
	display: flex;
	flex-direction: column;
	gap: calc(20px + 0.390625vw);
}

.content {
	@include flex(column, center, flex-start);

	@media screen and (min-width: 768px) {
		@include flex(row, space-between);
	}

	&-collapse {
		@include flex(column, center, flex-start);
		gap: 20px;

		@media screen and (min-width: 768px) {
			flex-direction: row;
		}
	}
}

.accommodation-heading {
	@include flex(column, center, flex-start);
	gap: calc(10px + 0.390625vw);
	color: $main-color;

	&__title {
		font-size: calc(18px + 0.390625vw);
	}

	&__location {
		font-size: calc(14px + 0.390625vw);
	}

	&-tags {
		@include flex(row, flex-start);
		flex-wrap: wrap;
		gap: calc(10px + 0.390625vw);

		&__item {
			text-align: center;
			color: white;
			border-radius: 10px;
			background-color: $main-color;
			padding: calc(2px + 0.390625vw) calc(25px + 0.390625vw);
			font-size: calc(10px + 0.390625vw);
		}
	}
}

accommodation-heading {
	@extend .accommodation-heading;
}

.host-and-rating {
	margin-top: calc(10px + 0.390625vw);

	@media screen and (min-width: 768px) {
		@include flex(column-reverse);
		gap: calc(15px + 0.390625vw);
		height: 100%;
		width: unset;
		margin-top: unset;
	}

	&__rating {
		@include flex;
		gap: calc(5px + 0.390625vw);

		&__item svg {
			width: calc(13px + 0.390625vw);
			height: calc(13px + 0.390625vw);
		}
	}
	width: 100%;
	@include flex(row, space-between);

	&__host {
		@include flex;
		gap: calc(10px + 0.390625vw);

		&-name__text {
			font-size: calc(12px + 0.5vw);
		}

		&__picture {
			width: calc(32px + 2.5vw);
			height: calc(32px + 2.5vw);
			border-radius: 50%;
		}
	}
}

host-and-rating {
	@extend .host-and-rating;
}
