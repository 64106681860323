@import '../../sass/main.scss';

/* Introducing magic formula
Goodbye '@media screen' breakpoints

		calc(--px + -vw)

From smallest screen size to max screen view
First value is the starting point
Second value is the screen view addition

		works with all rules
		font-sizes / paddings / margins / widths x heights / ...

Resulting to --> 95% less breakpoints */

.footer {
	@include flex(column);
	gap: 30px;
	width: 100%;
	height: calc(200px + 0.5vw);
	background-color: black;

	&__logo {
		padding-top: calc(5px + 2vw);
		height: 40px;
	}

	&__text {
		font-size: calc(12px + 0.390625vw);
		color: white;
	}
}
