@import 'base/reset';
@import 'utils/variables';
@import 'utils/mixins';
@import 'utils/placeholders';

/* Introducing magic formula
Goodbye '@media screen' breakpoints

		calc(--px + -vw)

From smallest screen size to max screen view
First value is the starting point
Second value is the screen view addition

		works with all rules
		font-sizes / paddings / margins / widths x heights / ...

Resulting to --> 95% less breakpoints */

.main-pages {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

a,
p {
	font-size: calc(12px + 0.390625vw);
}

h1 {
	font-size: calc(24px + 0.390625vw);
}

H2 {
	font-size: calc(18px + 0.390625vw);
}
