@import '../../sass/main.scss';

/* Introducing magic formula
Goodbye '@media screen' breakpoints

		calc(--px + -vw)

From smallest screen size to max screen view
First value is the starting point
Second value is the screen view addition

		works with all rules
		font-sizes / paddings / margins / widths x heights / ...

Resulting to --> 95% less breakpoints */

.nav-list {
	height: 100%;
	display: flex;
	align-items: center;
	gap: calc(5px + 4vw);

	&__item {
		position: relative;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: calc(12px + 0.8vw);

		&::after {
			z-index: 1000;
			content: '';
			position: absolute;
			width: 0;
			height: 2px;
			bottom: -3px;
			left: 0;
			background: $main-color;
			transition: width 0.3s;
		}
	}

	&__item {
		&:hover {
			&::after {
				width: 100%;
			}
		}

		&--active {
			&::after {
				width: 100%;
			}
		}
	}
}
