@import '../../sass/main.scss';

.notFound {
	height: 90vh;
	@include flex(column, space-evenly);

	&-heading {
		@include flex(column);

		&__title {
			font-size: calc(96px + 13vw);
			color: $main-color;
			font-weight: 700;
		}

		&__text {
			font-size: calc(18px + 0.390625vw);
			text-align: center;
			color: $main-color;
		}
	}

	&-homeLink__text {
		font-size: calc(14px + 0.390625vw);
		text-align: center;
		text-decoration: underline;
	}
}
