@import '../../sass/main.scss';

/* Introducing magic formula
Goodbye '@media screen' breakpoints

		calc(--px + -vw)

From smallest screen size to max screen view
First value is the starting point
Second value is the screen view addition

		works with all rules
		font-sizes / paddings / margins / widths x heights / ...

Resulting to --> 95% less breakpoints */

.gallery {
	border-radius: 25px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
	gap: calc(20px + 1vw);

	@media screen and (min-width: 768px) {
		background-color: $main-grey;
		grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
		padding: calc(40px + 1vw);
	}

	@media screen and (min-width: 900px) {
		grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
	}
}
